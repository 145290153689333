.container {
    padding: 10px;
}

.switch {

    &.MuiSwitch-root {
        .MuiSwitch-switchBase {
            .Mui-checked {
                color: red;
            }
        }


    }
}

.titleInput {
    margin-bottom: 6px;
}