@use "../../variables" as v;
@import "../../styles";

.card {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    height: 130px;
    width: 160px;
    z-index: 1000;
    margin: 0;
    padding: 2em;
    padding-top: 3em;
    gap: 1em;
    background-color: transparent;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    align-items: center;
    text-align: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.1);
    font-weight: 200;
    cursor: inherit;

    span {
        display: block;
        width: 70px;

        img {
            max-width: 100%;
        }
    }

    p {
        text-decoration: none !important;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.5rem;
        width: 100%;
    }
}

.containerChildren {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.blueLink {
    background-color: #06132f;
    color: white;

    p {
        color: white !important;
    }
}

.orangeLink {
    background-color: #ff6d00;
    color: white !important;

    p {
        color: white !important;
    }
}

.disabled {
    background-color: #656D7D;
    color: white !important;

    p {
        color: white !important;
    }

    &:hover {
        background-color: #656D7D;
    }
}