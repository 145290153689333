.main {
    min-height: 75vh;
    min-width: 80vw;
    height: 100%;
    width: calc(100% - 3em);
    padding: 0 1.5em;
    margin: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .actions {
        width: 100%;
        flex: 0;
        display: flex;
        align-items: center;
        justify-content: end;
        flex-wrap: wrap;
    }

    .filters {
        width: 100%;
        flex: 0;
        display: flex;
        align-items: center;
        justify-content: start;
        flex-wrap: wrap;
    }

    .filters-actions {
        width: 100%;
        flex: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .container {
        display: grid;
        width: 100%;
        height: calc(100% - 1.5em);
        padding-bottom: 1.5em;
        flex: 1;
    }
}