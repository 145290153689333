.users-count {
  display: flex;
  position: absolute;
  left: 10px;
  bottom: 10px;
  justify-content: center;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  line-height: normal;
  font-weight: 400;
}


.tooltip-info {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;

  .data-key {
    text-align: left;
  }

  .data-value {
    text-align: right;
  }
}