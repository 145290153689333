.root {
    cursor: pointer;
    position: relative;

    :global .MuiDataGrid-footer {
        justify-content: flex-start !important;
    }

    :global .MuiDataGrid-footerContainer {
        background-color: #E8EBF1;
    }

    :global .MuiDataGrid-columnHeaderWrapper {
        background-color: #E8EBF1;
    }

    :global .MuiDataGrid-columnHeaderTitle {
        font-weight: 700;
    }


}