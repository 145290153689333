.dropZone {
    border: 1.7px dotted #C5C7C9;
    border-radius: 10px;
    height: 30vh;
    width: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
        color: #FF6D00;
    }
}

.containerLoad {
    margin: auto;

    .textLoad {
        margin: auto;
        margin-bottom: 10px;
    }
}

.containerBtn {
    width: 100%;
}

.fileCharged {
    display: flex;
    flex-direction: column;
    min-width: 100px;

    h1 {
        font-size: 0.7em;
    }
}

.error {
    color: rgb(229, 0, 0);
}