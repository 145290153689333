/* DoubleTooltip.module.scss */
.double-tooltip-container {
    position: relative;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.tooltip {
    position: absolute;
    background-color: #0d2237cc;
    color: white;
    padding: 4px;
    border-radius: 10px;
    display: none;
    width: 180px;
    height: 50px;
    z-index: 1001 !important;

    ul {
        li {
            margin-top: 15px;
        }
    }
}

.first-tooltip {
    bottom: -25px;
    /* Cambia el valor para ajustar la posición arriba */
    left: 20px;

}


.double-tooltip-container:hover .tooltip {
    display: block;
}