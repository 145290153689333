@import "../../../../styles/sassUtils/indexUtils.scss";

.filterContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.filters {
    align-items: center;
    display: flex;
    justify-content: space-around;
    width: 100%;

    > :global(.MuiFormControl-root) {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        width: 20%;

        &:last-of-type {
            width: calc(20% - 82px);
        }
    }
}

.defaultButton {
    @include defaultBtn;
    width: auto;
}