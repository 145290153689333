.mapaModal {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
}

.searchBoxContainer {
    margin-bottom: 1rem;
    padding-right: 2rem;
}

.searchBox {
    border: 1px solid #000;
    width: 100%;
    height: 32px;
    padding: 0 12px;
    border-radius: 3px;
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
    z-index: 100;
    color: #000;
}
