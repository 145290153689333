// input type number arrow removal
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.paragraph {
    font-size: 1.5rem;
    font-weight: 200;
    text-align: start;
}

.inputs {
    margin: 1rem;

    .column {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 0.5em;
        justify-content: center;
        width: 100%;
    }
}

.item {
    display: flex;
    justify-content: center;
}

.label {
    display: block;
    text-align: center;
    width: 100%;
}

.defaultBtn {
    background: linear-gradient(90deg, #63d1a2 0%, #63c3d1 100%);
    border-radius: 50rem !important;
    color: #ffffff !important;
    font-family: "Roboto, sans-serif" !important;
    font-weight: 300 !important;
    letter-spacing: 0rem !important;
    margin: auto;
    opacity: 1;
    padding: 0.5em !important;
    text-transform: capitalize !important;
    width: 19rem;

    &:hover {
        background: linear-gradient(90deg, #13d5a5 0%, #13c5d5 100%);
    }

    &:disabled {
        background: #a7a7a7;
    }
}
