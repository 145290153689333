@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

* {
    font-family: "Lato", sans-serif;
}

body {
    margin: 0;
    text-decoration: none !important;
}

label {
    font-weight: bold !important;
}

.MuiDrawer-paperAnchorDockedLeft {
    border-right: none !important;
}

.MuiDataGrid-row:first-of-type {
    border-top: 1px solid #f2f2f2;
}

.MuiDataGrid-row:nth-of-type(even) {
    background-color: #fafafa !important;
}
.MuiDataGrid-row:nth-of-type(odd) {
    background-color: #fff !important;
}
.MuiDataGrid-row:nth-of-type(even).Mui-selected {
    background-color: #a7a7a7 !important;
    border: none !important;
    outline: none !important;
}
.MuiDataGrid-row:nth-of-type(even).Mui-selected:hover {
    background-color: #a7a7a7 !important;
    border: none !important;
    outline: none !important;
}
.MuiDataGrid-row:nth-of-type(odd).Mui-selected {
    background-color: #a7a7a7 !important;
    border: none !important;
    outline: none !important;
}
.MuiDataGrid-row:nth-of-type(odd).Mui-selected:hover {
    background-color: #a7a7a7 !important;
    border: none !important;
    outline: none !important;
}

.MuiDataGrid-cell {
    outline: none !important;
}
.MuiDataGrid-cell:hover {
    outline: none !important;
}
.MuiDataGrid-row:hover {
    background-color: #f2f2f2 !important;
}
.MuiDataGrid-columnHeader {
    outline: none !important;
}
