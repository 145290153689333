.container {
    display: flex;
    flex-direction: column;
}

.containerBack {
    display: flex;
    color: #FF6909;
    cursor: pointer;
    margin-bottom: 30px;
    gap: 4px;
    width: fit-content;

    p {
        color: #06132F;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
    }
}

.containerEvan {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
}

.containerEvan h2 {
    color: #06132F;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    text-align: start;
}

.containerEvan h3 {
    color: #FF6909;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    margin: 0;
    text-align: start;
}

.containerSection {
    width: 100%;
}

.containerPrincipal {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 40px;
    justify-content: space-between;
}

.containerPrincipalData {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.horizontal {
    display: flex;
    gap: 40px;
}

.img {
    background-color: rgba(0, 0, 0, 0.5);
    height: 200px;
    aspect-ratio: 4 / 5;
    margin-inline: 0;
    border-radius: 12px;
    margin-top: 0;
}

.containerData {
    display: flex;
    // flex-direction: column;
    gap: 8px;
    text-align: start;
    width: max-content;
    align-items: center;
}

.containerDataHorizontal {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    text-align: start;
    margin-bottom: 12px;
}

.containerDataHorizontalSmall {
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    text-align: start;
    margin-bottom: 12px;
}

.text {
    margin: 0;
    color: #5F6689;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.bold {
    color: #06132F;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.big {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.containerCards {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 50px;
}

.containerBottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 18px;
    grid-row-gap: 0px;
}

.containerMap {
    width: 100%;
    display: flex;
    flex-direction: column;
}

// .left{
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//     grid-column-gap: 18px;
//     grid-row-gap: 0px;
// }
.containerTitle {
    border-bottom: 1px solid #E5E9F0;
    padding-bottom: 16px;
    color: #06132F;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-align: start;
    margin-top: 50px;
    margin-bottom: 12px;

    p {
        margin: 0;
    }
}

// .right{
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//     grid-column-gap: 18px;
//     grid-row-gap: 0px;
// }
.containerBtn {
    display: flex;
    gap: 40px;
    justify-content: center;
    margin-top: 60px;

    button {
        background-color: #FF6D00;
        color: #FFF;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 24px;
        border: none;
        padding: 12px 30px;
        cursor: pointer;
    }

    button:disabled {
        background-color: #ff6f006d;
    }
}

.contLat {
    display: flex;
    flex-direction: column;
}