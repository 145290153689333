.helperParagraph {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    position: relative;
    top: 5%;
    width: min-content;

    h2,
    p {
        color: #06132f;
        width: max-content;
    }

    h2 {
        font-size: 24px;
        font-weight: 400;
        margin: 0.2em 0;
    }

    p {
        font-size: 18px;
        font-weight: 100;
    }
}
