.container {
    background-color: #e6e6e694;
    width: 203px;
    padding: 20px;
    text-align: start;
    border-radius: 14px;
    overflow: hidden;
}

.container p {
    margin: 0;
}

.containerTop {
    display: flex;
    justify-content: space-between;
}

.line {
    width: 100%;
    height: 1px;
    background-color: #aaaaaa;
    margin-top: 12px;
    margin-bottom: 20px;
}

.title {
    color: #06132F;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.result {
    // color: #02893B;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;
}

.containerIcon {
    padding: 8px;
    height: fit-content;
}

.success .result {
    color: #02893B;
}

.success .containerIcon {
    background-color: #00ff6a22;
    border-radius: 4px;
    color: #02893B;
}

.error .result {
    color: #EF3131;
}

.error .containerIcon {
    background-color: #ef313122;
    border-radius: 4px;
    color: #EF3131;
}

.not .result {
    color: #EBA701;
}

.not .containerIcon {
    background-color: #eba90122;
    border-radius: 4px;
    color: #EBA701;
}

.point {
    color: #06132F;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;
}

.more {
    width: fit-content;
    margin: auto !important;
    cursor: pointer;
    color: #FF6909;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
    text-transform: uppercase;
}