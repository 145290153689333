.buttonsContainer {
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: end;
    width: min-content;
    position: relative;
}

.icons {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.iconsNoFilters {
    align-items: center;
    display: flex;
    justify-content: end;
    width: 100%;
}