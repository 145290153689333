.container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .header {
    flex: 0;
    width: 100%;
  }

  .body {
    flex: 1;
    width: 100%;
    height: 100%;
  }
}