.root {
    color: white;
    padding: 1.2em 0.8em !important;
    position: absolute;
    top: 0px !important;
    transition: all 1s ease-in-out !important;
    width: 100%;
    z-index: 99999999 !important;

    :global .MuiSnackbarContent-root {
        background-color: transparent;
        box-shadow: none;
        font-size: 1rem;
        min-width: unset;
        padding: 0;
    }
}

.success {
    background-color: #30C952;
}

.error {
    background-color: #FF3C34;
}

.warning {
    background-color: #ffa91c;
}

.alert {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    width: 100%;
}